@import url("https://fonts.googleapis.com/css2?family=Cinzel&family=Montserrat:wght@100;200;300;400;500;600;700&display=swap");
iframe {
  display: none;
}
html,
body,
.App,
#root {
  margin: 0;
  padding: 0;
  text-align: center;
  background: #fff;
  font-family: "Montserrat";
  height: 100%;
  outline: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.fixedBottomBackground {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999;
  height: 150px;
  width: 100%;
  background-image: linear-gradient(to top, #fff, transparent);
}
h3 {
  color: #555;
}
.fixedBackground {
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #ffb199;
  background-image: radial-gradient(
      at 62% 32%,
      hsla(337, 99%, 77%, 1) 0px,
      transparent 50%
    ),
    radial-gradient(at 48% 7%, hsla(261, 64%, 72%, 1) 0px, transparent 50%),
    radial-gradient(at 48% 1%, hsla(77, 76%, 66%, 1) 0px, transparent 50%),
    radial-gradient(at 1% 30%, hsla(245, 98%, 66%, 1) 0px, transparent 50%),
    radial-gradient(at 78% 57%, hsla(338, 99%, 71%, 1) 0px, transparent 50%),
    radial-gradient(at 5% 75%, hsla(65, 65%, 61%, 1) 0px, transparent 50%),
    radial-gradient(at 57% 70%, hsla(35, 79%, 71%, 1) 0px, transparent 50%);
}
a {
  text-decoration: none;
  color: #333;
}
.App {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
body::-webkit-scrollbar {
  display: none;
}
.App::-webkit-scrollbar {
  display: none;
}
input {
  outline: none;
  width: calc(100% - 16px);
  background: transparent !important;
  font-family: "Montserrat";
  font-size: 15px;
  letter-spacing: 1px;
  // border: 1px solid #ccc;
  color: #fff;
  border-radius: 7px;
  padding: 8px;
}
textarea {
  outline: none;
  padding: 8px;
  background: transparent;
  font-family: "Montserrat";
  font-size: 15px;
  letter-spacing: 1px;
  resize: none;
  width: calc(100% - 16px);
  height: 60px;
  border-radius: 7px;
  border: 1px solid #ccc;
}
form {
  width: 90%;
  margin: 0 auto;
}

button {
  outline: none;
  font-family: "Montserrat";
  font-weight: 600;
  letter-spacing: 1px;
}
select {
  background: transparent;
  font-family: "Montserrat";
}
.homepageBorder {
  padding-top: 30px;
  width: 95%;
  max-width: 550px;
  height: 92vh;
  max-height: 500px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 20px;
  input {
    width: calc(100% - 18px);
    max-width: 500px;
  }
}
.toolbar {
  position: fixed;
  bottom: 0;
  z-index: 2;
  width: 100%;
  height: 50px;
}
.toolbarContent {
  display: flex;
  align-items: center;
  width: 90%;
  height: 100%;
  margin: 0 auto;
}
.toolbarContent img {
  height: 80%;
}
.toolbarContent h1 {
  margin: 0;
  padding: 0;
  color: #111;
  font-weight: 400;
  font-size: 15px;
  margin-left: 10px;
  text-align: left;
  font-family: "Cinzel";
}

/* HOMEPAGE */
.menuBar {
  position: fixed;
  z-index: 400;
  top: calc(100% - 150px);
  width: 100%;
  height: 150px;
}
.homepage {
  position: relative;
  width: 100%;
  display: inline-block;
  margin-bottom: -5px;
  h4 {
    font-weight: 300;
    font-size: 17px;
    letter-spacing: 4px;
    margin: 0;
    color: #fff;
  }
}
.AppScroll {
  position: relative;
  display: flex;
  overflow: hidden;
  .scroll {
    position: absolute;
    top: -100%;
    left: 300%;
    transform: translate(-200%);
    width: 100%;
  }
}
.homepageMain {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: inline-block;
  h4 {
    font-weight: 100;
    font-size: 17px;
    letter-spacing: 4px;
    margin: 0;
    color: #fff;
  }
}
.homepageTop {
  position: relative;
  display: inline-block;
  width: 100%;
  animation: slide 0.5s ease;
  .priceContainer {
    width: 100%;
    overflow: scroll;
    scroll-snap-type: mandatory;
  }
  .priceContainer::-webkit-scrollbar {
    display: none;
  }
  .priceContainerScroll {
    width: fit-content;
    margin: 10px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    scroll-snap-type: mandatory;
  }
  .priceCard {
    max-width: 350px;
    padding: 10px;
    border-radius: 20px;
    text-align: center;
    scroll-snap-align: center;
    margin: 0 auto;
    svg {
      font-size: 40px;
      color: #555;
      margin-top: 20px;
    }
    p {
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-align: center;
    }
    h3 {
      font-size: 30px;
      margin-bottom: 0;
    }
    h5 {
      color: #555;
      margin: 5px 0;
    }
  }
  .utilityBox {
    display: flex;
    align-items: start;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 90%;
    margin: 0 auto;
    .utilityIcons {
      backdrop-filter: blur(10px);
      -webkit-backdrop-filter: blur(10px);
      text-align: center;
      background: rgba(255, 255, 255, 0.5);
      margin: 8px auto;
      width: 80px;
      height: 80px;
      padding: 10px;
      border-radius: 20px;
      img {
        width: 30px;
        margin-top: 10px;
      }
      p {
        text-align: center;
        font-weight: 500;
        font-size: 12px;
      }
    }
  }
  h2 {
    width: 90%;
    font-weight: 500;
    margin: 0 auto;
    text-align: center;
    color: #fafafa;
  }
  p {
    width: 90%;
    margin: 0 auto;
    color: #777;
    text-align: left;
    font-size: 13px;
  }
  button {
    background: transparent;
    border: none;
    svg {
      font-size: 10px;
      margin-right: 10px;
    }
  }
}
.subheaderImg {
  width: 30%;
  max-width: 300px;
}

.homepageCenter {
  position: relative;
  width: 100%;
  min-height: 100vh;
  height: fit-content;
  padding: 0;
  animation: 0.35s fadeIn ease-in-out;
  .homepageLogo {
    border-bottom-left-radius: 2000px 800px;
    border-bottom-right-radius: 2000px 800px;
    position: relative;
    height: 100vh;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background-image: url("./Assets/background.svg");
    background-size: cover;
    background-position: left;
    background-repeat: no-repeat;
    .homepageWeather {
      position: absolute;
      top: 20px;
      left: 5%;
      color: #fff;
      border-radius: 7px;
      text-align: left;
      backdrop-filter: blur(10px);
      background: rgba(0, 0, 0, 0.3);
      padding: 10px;
      text-align: center;
      h3 {
        font-size: 18px;
        margin: 0;
        color: #fff;
      }
      p {
        font-size: 13px;
        font-weight: 600;
        margin: 0;
      }
      img {
        width: 36px;
        height: 36px;
      }
    }
    svg {
      position: absolute;
      top: 20px;
      right: 5%;
      border-radius: 50%;
      padding: 10px;
      color: #fff;
      background: rgba(0, 0, 0, 0.2);
      backdrop-filter: blur(10px);
    }
    .homepageLogoText {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: rgba(0, 0, 0, 0.3);
      margin: 0 auto;
      width: 80%;
      backdrop-filter: blur(10px);
      max-width: 450px;
      border-radius: 20px;
      color: #333;
    }
    h1 {
      color: #fff;
      font-family: "Cinzel";
      letter-spacing: 2px;
      font-size: 30px;
      font-weight: 500;
      margin: 0;
      margin-bottom: 8px;
      text-align: center;
      padding: 20px 20px 0 20px;
    }
    h4 {
      padding: 0 20px 20px 20px;
    }
  }
  form {
    width: 100%;
    margin-top: -20%;
    position: relative;
  }
  .DateRangePicker {
    cursor: pointer;
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%, -20px);
    z-index: 999;
    background: #753a9f;
    background: linear-gradient(322deg, #753a9f 0%, #f25b6c 80%);
    width: 90%;
    max-width: 500px;
    padding: 5px 0;
    border-radius: 20px;
    ::placeholder {
      font-size: 16px;
      font-weight: 600;
      color: #fff;
    }
  }
}
.nearbyBox {
  width: calc(100% - 20px);
  max-width: 600px;
  padding: 0 10px;
  margin: 0 auto;
  margin-bottom: 4px;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  text-align: left;
  border-radius: 7px;
  h5 {
    color: #555;
    margin: 0;
  }
  p {
    width: 100%;
    background: transparent;
    border-radius: 0;
    font-size: 13px;
    font-weight: 500;
    margin-top: 5px;
    span {
      float: right;
    }
  }
}

.modalHeader {
  color: #111;
  font-family: "Cinzel";
  letter-spacing: 2px;
  font-size: 20px;
  margin: 0 auto;
  padding: 10px;
  border: 1px solid #111;
  width: fit-content;
  span {
    font-family: "Montserrat";
    font-size: 15px;
    font-weight: 200;
  }
}
.smallButton {
  font-size: 18px;
  font-weight: 400;
  font-family: "Montserrat";
  width: 58%;
  height: 45px;
  border: 1px solid #ff6f61;
  color: #fff;
  background: #ff7061;
  position: fixed;
  bottom: 0%;
  right: 2%;
  z-index: 300;
  border-top-right-radius: 7px;
}
.smallButtonDesktop {
  font-size: 15px;
  font-weight: 400;
  font-family: "Montserrat";
  width: 180px;
  height: 38px;
  border: 1px solid #ff6f61;
  color: #fff;
  background: #ff7061;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 300;
  border-radius: 7px;
}
.smallButtonLeft {
  font-size: 18px;
  font-weight: 200;
  width: 18%;
  height: 45px;
  border: 1px solid #ff6f61;
  color: #fff;
  background: #ff7061;
  position: fixed;
  bottom: 0;
  left: 2%;
  z-index: 300;
  border-top-left-radius: 7px;
}
.smallButtonCenter {
  font-size: 18px;
  font-weight: 200;
  width: 18%;
  height: 45px;
  border: 1px solid #ff6f61;
  color: #fff;
  background: #ff7061;
  position: fixed;
  bottom: 0%;
  left: 21%;
  z-index: 300;
}
.cancelButton {
  width: 100%;
  background: transparent;
  border: none;
  border-radius: 7px;
  height: 35px;
}
.loginCenter {
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 500px;
  .mainButton {
    width: 100%;
    height: 40px;
    background: #ff6f61;
    border: none;
    border-radius: 7px;
    color: #fff;
    margin-top: 10px;
  }
  h2 {
    color: #777;
  }
  h5 {
    color: #777;
  }
  label {
    margin: 5px 0;
    display: inline-block;
  }
}
.passInput {
  position: relative;
  svg {
    position: absolute;
    top: 60%;
    right: 5px;
    color: #ccc;
  }
}
.footer {
  width: 100%;
  background: #eee;
  padding: 20px 0;
  text-align: left;
  box-shadow: -1px -1px 3px #ccc;
  padding-bottom: 80px;
  a {
    h4 {
      letter-spacing: 2px;
    }
  }
  input {
    width: 88%;
    border: none;
    background: #fff;
    padding: 6px;
    max-width: 500px;
  }
  button {
    width: 7%;
    background: #ff6f61;
    color: #fff;
    padding: 5px;
    svg {
      margin-left: 20%;
    }
  }
}
.footerContent {
  width: 90%;
  height: 100%;
  margin: 0 auto;
  h3 {
    font-size: 13px;
  }
  h4 {
    margin: 0;
    padding: 0;
    color: #777;
    padding: 5px 0;
    font-weight: 400;
    font-size: 13px;
  }
  .footerCredits {
    font-size: 10px;
    color: #777;
    width: 100%;
    letter-spacing: 2px;
  }
}

.paymentFooter {
  margin-left: 10%;
  width: 60%;
  max-width: 400px;
}

.contactDetails {
  text-align: left;
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .contactBox {
    width: 43%;
    max-width: 200px;
    padding: 20px 10px;
    border-radius: 7px;
    background: rgba(255, 255, 255, 0.5);
    font-size: 15px;
    overflow: hidden;
    margin: 5px auto;
    a {
      height: 100%;
    }
    img {
      width: 36px;
    }
    strong {
      font-size: 20px;
    }
    .flexParagraph {
      display: flex;
      align-items: center;
      svg {
        margin-right: 5px;
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transition: 1s;
  }
  100% {
    opacity: 1;
    transition: 1s;
  }
}

form:invalid {
  .bookingInfo {
    display: none;
    transition: 1s;
  }
}
form:valid {
  .empty {
    display: none;
    transition: 1s;
  }
  .DateRangePicker {
    display: none;
  }
}
.weatherIcon {
  font-size: 40px !important;
}
@media screen and (max-width: 645px) {
  .swiper {
    width: 200%;
    height: 300px;
    margin-left: -50%;
    .swiper-slide {
      background: rgba(255, 255, 255, 0.5);
      border-radius: 20px;
      backdrop-filter: blur(20px);
      -webkit-backdrop-filter: blur(20px);
    }
    .swiper-slide-shadow-left {
      border-radius: 20px;
    }
    .swiper-slide-shadow-right {
      border-radius: 20px;
    }
  }
  .swiperWrapper {
    width: 100%;
    overflow: hidden;
  }
  .smallButtonDesktop {
    display: none;
  }
  .background {
    height: 100%;
    max-height: 180%;
    min-width: 675px;
    position: absolute;
    top: 0;
    left: -100px;
  }
}
@media screen and (min-width: 646px) {
  .swiper {
    width: 100%;
    .swiper-slide {
      background: rgba(255, 255, 255, 0.5);
      border-radius: 20px;
      backdrop-filter: blur(20px);
      -webkit-backdrop-filter: blur(20px);
    }
    .swiper-slide-shadow-left {
      border-radius: 20px;
    }
    .swiper-slide-shadow-right {
      border-radius: 20px;
    }
  }
  .swiperWrapper {
    width: 100%;
    overflow: hidden;
  }
  .smallButtonLeft {
    display: none;
  }
  .background {
    width: 150%;
    position: absolute;
    top: -40%;
    left: 50%;
    transform: translate(-50%);
  }
  .smallButtonCenter {
    display: none;
  }
  .smallButton {
    display: none;
  }
}
/*MODAL*/
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 300;
}
.modalWrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 300;
  transition: 1s ease-in-out;
}
.modalLang {
  position: absolute;
  top: 58px;
  right: 5%;
  background-color: rgba(0, 0, 0, 0.3);
  width: fit-content;
  max-height: 90%;
  pointer-events: all;
  overflow: auto;
  border-radius: 20px;
  border-top-right-radius: 0;
  scrollbar-width: none;
  -ms-overflow-style: none;
  text-align: left;
  backdrop-filter: blur(10px);
  animation: scaleUp 0.35s ease;
  p {
    font-weight: 500;
    font-size: 14px;
    padding: 15px;
    margin: 0;
    color: #fff;
  }
  p:hover {
    background: rgba(255, 255, 255, 0.5);
  }
  h3 {
    padding: 20px;
    margin: 0;
    font-weight: 600;
  }
}
.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: #fff;
  width: 90%;
  max-width: 450px;
  height: fit-content;
  max-height: 90%;
  pointer-events: all;
  overflow: auto;
  border-radius: 7px;
  scrollbar-width: none;
  -ms-overflow-style: none;
  h4 {
    position: fixed;
    margin: 0;
    padding: 0;
    font-weight: 400;
    font-size: 20px;
  }
  button {
    width: 100%;
    padding: 10px 0;
    background: #ff6f61;
    color: #fff;
    border: none;
    border-radius: 7px;
  }
}
.modalForm {
  width: 90%;
  margin: 0 auto;
  h1 {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1px;
  }
  h5 {
    text-align: center;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 1px;
    margin: 5px;
  }
  label {
    display: inline-block;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 5px;
    font-size: 11px;
    font-weight: 600;
    color: #555;
    text-align: left;
  }
}

.headerFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  max-width: 600px;
  padding: 30px 0;
  margin: 0 auto;
  h1 {
    font-size: 15px;
  }
}
.textInput {
  width: 100%;
}
.flexDivTop {
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  width: 100%;
  text-align: left;
  margin: 0;
}
.flexDiv {
  width: 100%;
  margin: 0;
  .counter {
    text-align: center;
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .counterHandler {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      border-radius: 7px;
      text-align: center;
      overflow: hidden;
      margin-top: 10px;
      button {
        width: 30%;
        margin: 0;
        padding: 5px;
        background: #fff;
        color: #ff6f61;
        font-size: 18px;
      }
      p {
        font-size: 18px;
        padding: 0;
        margin: 0;
      }
    }
    label {
      text-align: left;
      width: 100%;
      color: #fff;
      font-size: 18px;
      font-weight: 500;
    }
  }
  div {
    width: 90%;
    margin: 0;
    padding: 0;
    select {
      height: 35px;
      width: 98%;
      border: 1px solid #555;
      border-radius: 7px;
      outline: none;
    }
  }
}

.feedbackRow::-webkit-scrollbar {
  display: none;
}
.feedbackRow {
  scrollbar-width: none; /* Firefox 64 */
  -ms-overflow-style: none; /* IE 11 */
  overflow-x: scroll;
  margin-top: 10px;
  .feedbackFlex {
    display: flex;
    align-items: top;
    justify-content: space-between;
    width: fit-content;
    margin: 20px auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    scroll-behavior: smooth;
    .feedbackCard {
      width: 200px;
      margin-left: 20px;
      background: rgba(255, 255, 255, 0.5);
      padding: 10px;
      height: 200px;
      border-radius: 7px;
      position: relative;
      p {
        color: #333;
        font-weight: 500;
      }
      strong {
        position: absolute;
        bottom: 20px;
        left: 20px;
        span {
          color: #999;
        }
      }
      button {
        background: #ff6f61;
        color: #fff;
        padding: 8px;
        border-radius: 7px;
      }
    }
    .feedbackCardLast {
      width: 200px;
      margin-left: 20px;
      margin-right: 20px;
      background: transparent;
      border: 1px solid #ddd;
      padding: 10px;
      height: 200px;
      border-radius: 7px;
      position: relative;
      h3 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: 0;
      }
    }
  }
}
.capitalize {
  text-transform: capitalize;
  display: inline-block;
  font-weight: 600;
}
.rightAlign {
  float: right;
}

.bookingInfo {
  text-align: left;
  padding: 20px;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  width: calc(90% - 40px);
  animation: 0.35s fadeIn;
  background: rgb(117, 58, 159);
  background: linear-gradient(
    322deg,
    rgba(117, 58, 159, 1) 0%,
    rgba(242, 91, 108, 1) 80%
  );
  z-index: 999;
  border-radius: 20px;
  label {
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  label strong {
    color: #fff;
    font-size: 18px;
  }
  label span {
    color: #fff;
  }
  p {
    width: fit-content;
    font-size: 13px;
    color: #fff;
    strong {
      display: inline-block;
      width: 100px;
    }
  }
  h3 {
    color: #fff;
    margin: 5px 0;
  }
  h5 {
    color: #eee;
    letter-spacing: 1px;
    font-weight: 500;
    font-size: 15px;
    margin: 0;
    text-align: center;
  }
  button {
    width: 100%;
    margin-top: 10px;
    padding: 10px;
    border: 0;
    border-radius: 7px;
    background: #ff6f61;
    color: #fff;
  }
  .whiteButton {
    background: #eee;
    color: #555;
  }
  .transparentButton {
    color: #eee;
    background: transparent;
  }
}

.bookingInfoFrame {
  margin: 0 auto;
  text-align: left;
  position: relative;
  padding: 10px;
  width: calc(100% - 20px);
  border-radius: 7px;
  animation: 0.35s fadeIn;
  transition: 3s ease-in-out;
  p {
    width: 100%;
    font-size: 13px;
    strong {
      display: inline-block;
      width: 100px;
    }
  }
  button {
    width: 100%;
    padding: 10px 0;
    border: 1px solid #fff;
    border-radius: 7px;
    background: #fff;
    color: #999;
    transition: 1s;
  }
}
.manageProducts {
  width: 100%;
  max-width: 80rem;
  margin: 0 auto;
  margin-bottom: 20px;
  color: #777;
  background-color: #000;
  th {
    display: flex;
  }
  a {
    color: #999;
    margin: 0;
    padding: 0;
  }

  h3 {
    display: inline-block;
    color: #999;
    border-bottom: 2px solid transparent;
    margin-right: 10px;
  }

  .selectedButton {
    border-bottom: 2px solid #ff6f61;
    color: #fff;
  }
}
table {
  width: 100%;
  text-align: left;
  td {
    .backButton {
      background: #111;
    }
  }
}
.homepageFitBlack {
  background: #000;
  width: 100%;
  .homepageTopFit {
    width: 90%;
    margin: 0 auto;
    h2 {
      margin-bottom: 0;
      color: #fff;
      text-align: left;
      width: 100%;
      max-width: 80rem;
      margin: 10px auto;
    }
  }
  button {
    background: #fff;
    color: #eee;
    border: none;
    width: 100%;
    padding: 10px 0;
    border-radius: 7px;
  }
}
table.results {
  align-items: top;
  text-align: left;
  color: #fff;
  tr {
    &:nth-child(even) {
      background: #111;
    }
  }
  p {
    padding: 5px 5px;
    font-size: 10px;
  }
  strong {
    display: inline-block;
    width: 80px;
  }
  button {
    background: transparent;
    border: none;
    margin: 0;
    padding: 0;
  }
}

/* SLIDER */
.slider {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
.slider-image {
  width: auto;
  height: 80%;
  border-radius: 7px;
}
.slide {
  opacity: 0;
  transition: 1s ease-in-out;
}
.slide.active {
  width: 100%;
  opacity: 1;
  transition: 1s ease-in-out;
}
.slide-icon-left {
  padding-left: 5px;
  font-size: 15px;
  height: 100%;
  color: #fff;
  margin-right: -20px;
  z-index: 200;
  transition: 0.3s ease-in-out;
  position: absolute;
  top: 30%;
  left: 10px;
  transform: translate(-50%, -50%);
}
.gallery::-webkit-scrollbar {
  display: none;
}
.gallery {
  overflow-x: scroll;
  height: 100%;
  scrollbar-width: none;
  -ms-overflow-style: none;
  .galleryScroll {
    background: transparent;
    width: fit-content;
    height: fit-content;
    display: flex;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: 15px 0;
    .galleryScrollContent {
      position: relative;
      display: inline-block;
      width: 350px;
      height: 300px;
      margin: 0;
      background: #eee;
      overflow: hidden;
      scroll-snap-align: center;
      margin: 0 5px;
      border-radius: 20px;
      img {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        width: 100%;
        vertical-align: center;
      }
    }
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(500px) scale(0.2);
  }
  100% {
    transform: translateX(0px) scale(1);
  }
}
@keyframes scaleUp {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(300px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes expand {
  0% {
    transform: translateX(1400px);
  }
  100% {
    transform: translateX(0px);
  }
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: calc(100% - 20px);
  height: calc(101% - 20px);
  background-color: #ff6f61;
  color: #fff;
  text-align: center;
  padding: 10px;
  border-radius: 6px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  svg {
    font-size: 20px;
  }
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
.tooltip:active .tooltiptext {
  visibility: visible;
}

::placeholder {
  color: #fff;
}

.footerLink {
  text-align: center;
  color: #333;
  span {
    color: red;
  }
}
.homepageTop .feedbackParagraph {
  color: #fff;
  text-align: center;
}

.priceCalendar {
  width: 30%;
  margin-bottom: -20px;
}
